@import 'styles/variables';

.link {
  background-color: $landing-grey;
  color: $white;
  border-radius: 6px;
  padding: rem-calc(14) rem-calc(54);
  font-size: rem-calc(14);
  font-weight: 700;
  letter-spacing: .25px;
  text-transform: uppercase;

  &:hover{
    color: $white;
  }
}