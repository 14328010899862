@import "/styles/variables";

.root {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4;
  font-size: 16px;
  color: $landing-dark-grey;
  background-color: #fff;

  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
  }

  h1 {
    letter-spacing: -1.5px;
    font-weight: 800;
  }

  h2 {
    letter-spacing: -0.5px;
  }

  .extra-bold {
    font-weight: 800;
  }

  /*h3 {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 700;
  }*/
}