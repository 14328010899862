@import "/styles/variables";

.root {
  padding: 100px 0 100px;
  overflow: hidden;
  :global(.section-subtitle) {
    margin-top: 24px;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 140%;
    letter-spacing: 0.15px;
  }
  :global(.section-title) {
    font-size: 4.5rem;
    line-height: 120%;
    letter-spacing: -1.5px;
    font-weight: 800;
    white-space: pre-line;
  }
}

.main_image {
  position: absolute;
  mix-blend-mode: darken;
  margin: auto;
  right: calc(calc(100vw - 1360px) / 2);
}

.text{
  line-height: 120%;
  font-weight: 700 ;
  font-size: 32px;
  margin-top: 20px;

}
.button {
  display: inline-block;
  min-width: 270px;
  margin-top: 24px;
  padding: 22px 32px 22px 32px;
  background: rgba(255, 0, 52, 0.7);
  border-radius: 6px;
  line-height: 140%;
  color: $white;
  cursor: pointer;
  text-align: center;
  transition: background-color .3s ease;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  &:hover {
    background: $primary;
  }
}

.plates {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  max-width: 62%;
  .plate {
    font-weight: 600;
    line-height: 140%;
    padding: 11px 16px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 95%;
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.9);
    //backdrop-filter: blur(12px);
    border: 1px solid rgba(39, 69, 104, 0.3);
    box-sizing: border-box;
    //backdrop-filter: blur(2px);
    border-radius: 8px;
    //cursor: pointer;
    z-index: 999;
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

.arrowUp {
  width: 50vw;
  max-width: 80px;
  max-height: 80px;
  height: 50vw;
  background: rgba(39, 69, 104, 0.7);
  border-radius: 8px;
  padding: 25px 30px 25px 30px;
  position: fixed;
  z-index: 9999;
  bottom: 40px;
  left: 40px;
  transition: background .3s linear;

  &:hover {
    background: rgba(39, 69, 104, 0.8);
  }

  cursor: pointer;

  :global(.MuiSvgIcon-root) {
    font-size: 40px;
    transform: rotate(-90deg);
    position: absolute;
    right: 20px;
    top: 20px;
    fill: $white;
  }
}

.chat {
  display: none;
  width: 50vw;
  max-width: 80px;
  max-height: 80px;
  height: 50vw;
  background: #677c95;
  border-radius: 8px;
  padding: 25px 30px 25px 30px;
  position: fixed;
  z-index: 9999;
  right: 40px;
  bottom: 40px;

  :global(.MuiSvgIcon-root) {
    fill: white;
  }
}

@media (max-width: 1400px) {
  .root {
    padding: 60px;

    .main_image {
      position: absolute;
      mix-blend-mode: darken;
      margin: auto;
      right: calc(calc(100vw - 1360px) / 2);
      top: 20%;
    }

    .arrowUp {
      max-width: 40px;
      max-height: 40px;

      :global(.MuiSvgIcon-root) {
        top: 10px !important;
        right: 10px !important;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;

    .button {
      max-width: 287px;
      z-index: 99;
    }
    :global(.section-title) {
      //z-index: 99;
    }
    :global(.section-subtitle) {
      //z-index: 99;
    }
  }

}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px;

    .main_image {
      max-width: calc(100vw - 40px);
      right: 0;
      top: 15%;
    }

    .arrowUp {
      max-width: 56px;
      max-height: 56px;
      left: 20px;
      bottom: 20px;

      :global(.MuiSvgIcon-root) {
        right: 10px;
        top: 10px;
      }
    }

    :global(.section-title) {
      font-size: 52px;
    }

    :global(.section-subtitle) {
      font-size: 18px;
      backdrop-filter: blur(10px);
      max-width: max-content;
    }

    .button {
      z-index: 99;
    }

    .plates {
      max-width: 80%;

      .plate {
        font-size: 14px;
        background: rgba(255, 255, 255, 0.9);
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .root {
    padding: 0.625rem;

    .main_image {
      display: block !important;
      position: relative;
      top: 0;
      right: 0 !important;

      img {
        max-width: calc(140vw - 40px) !important;
        position: relative;
        //right: 20%;
        max-height: 215px;
        left: -20%;
      }

    }
    :global(.section-title) {
      font-size: 40px;
    }

    .button {
      display: flex;
      max-width: 100%;
      justify-content: center;
    }

  }
  .arrowUp {
    display: none;
    max-width: 56px;
    max-height: 56px;
    left: 20px;
    bottom: 20px;

    :global(.MuiSvgIcon-root) {
      right: 10px;
      top: 10px;
    }
  }

  .plates {
    //background: red;
    max-width: 300% !important;
    width: 230% !important;
    position: relative;
  }
}