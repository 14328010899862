@import 'styles/variables';

.mobile-app {
  padding: rem-calc(120) 0;
  background-color: $landing-back-grey;

  @media (max-width: rem-calc(1400)) {
    padding: rem-calc(60);
  }

  @media (max-width: $tablet-width) {
    padding: rem-calc(40);
  }

  @media (max-width: rem-calc(650)) {
    padding: rem-calc(40) rem-calc(20);
  }

  :global(.swiper-slide-active) {
    transition: all .2s;
    transform: scale(1.2, 1.2);

    @media (max-width: rem-calc(560)) {
      transform: scale(1, 1);
    }
  }

  &__header-wrapper {
    max-width: 85rem;
    margin: auto;
  }

  &__title {
    @media (max-width: rem-calc(650)) {
      font-size: 32px;
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
    margin-top: rem-calc(12);
    font-size: rem-calc(20);
    font-weight: 700;
    line-height: 1.4;

    @media (max-width: rem-calc(650)) {
      font-size: rem-calc(16);
    }
  }

  &__slider {
    margin-top: rem-calc(130);

    @media (max-width: rem-calc(560)) {
      margin-top: rem-calc(50);
    }
  }

  &__slider-content{
    position: relative;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;


    @media (max-width: rem-calc(560)) {
      flex-direction: row;
      justify-content: center;
    }

    &--2x{
      display: flex;
      @media (max-width: rem-calc(560)) {
        display: none;
      }
    }

    &--1x{
      display: none;

      @media (max-width: rem-calc(560)) {
        display: flex;
      }
    }
  }

  &__img {
    //display: block;
    //max-width: none !important;
    max-width: 78% !important;
    height: auto;

    &--large {
      max-width: 100% !important;
    }

    @media (max-width: rem-calc(560)) {
      margin: 0 rem-calc(30);
      max-width: 100% !important;
    }

    @media (max-width: rem-calc(400)) {
      margin: 0 rem-calc(10);
    }
  }

  &__navigation {
    margin-top: rem-calc(100);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: rem-calc(560)) {
      margin-top: rem-calc(50);
    }
  }

  &__btn-nav {
    cursor: pointer;

    &--desktop {
      @media (max-width: rem-calc(560)) {
        display: none;
      }
    }

    &--mobile {
      position: absolute;
      display: none;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;

      @media (max-width: rem-calc(560)) {
        display: block;
      }
    }

    &--prev {

      @media (max-width: rem-calc(360)) {
        left: -17px;
      }
    }

    &--next {
      right: 0;

      @media (max-width: rem-calc(360)) {
        right: -17px;
      }
    }

    > svg {
      width: rem-calc(36);
      height: rem-calc(36);

      @media (max-width: rem-calc(400)) {
        width: rem-calc(27);
        height: rem-calc(27);
      }
    }
  }

  &__description-wrapper {
    margin: 0 rem-calc(45);
    width: rem-calc(550);
    text-align: center;

    @media (max-width: rem-calc(550)) {
      margin: 0 rem-calc(20);
    }

    @media (max-width: rem-calc(560)) {
      width: auto;
      margin: 0;
    }
  }

  &__heading-tertiary {
    @media (max-width: rem-calc(560)) {
      display: none;
    }
  }
}