@import "/styles/variables";

$cursor-size: 24px;

.ui_rows {
  input {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
}


.root {
  padding: 120px 0;
  background-color: $landing-back-grey;
  overflow: hidden;

  :global(.ui-rows) {
    background: red !important;

    input {
      padding-bottom: 2rem !important;
    }
  }

  :global(.section-title) {
    margin: 0 auto 12px;
  }

  :global(.section-subtitle) {
    font-size: 20px;
    margin-top: 40px;

    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: 0.15px;
    }

    span:first-child {
      color: #BE0027;
    }
  }
}

.tariffs {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  // .tariff:first-child {
  //   .price {
  //     //margin-top: 78px;
  //   }
  // }

  .tariff:last-child {
    background: rgba(255, 0, 0, 0.1);

    .badge {
      background: rgba(255, 0, 52, 0.3);

      span {
        color: #BE0027;
      }
    }

    .price {
      span {
        color: #BE0027;
      }
    }
    .description {
      span {
        color: #BE0027;
      }
    }
    .button {
      background-color: #BE0027;

      &:hover {
        background: $primary;
      }
    }
  }
  .tariff {
    background-color: #e4e7f0;
    padding: 0 50px 40px 30px;

    .badge {
      width: 100%;
      text-align: center;
      display: inline-block;
      padding: 8px 20px 8px 40px;
      background: rgba(39, 69, 104, 0.3);
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.15px;
      line-height: 120%;
      margin-left: -30px;
      margin-top: 20px;
    }
    .price {
      margin-top: 20px;

      span:first-child {
        font-size: 30px;
        font-weight: 700;
        text-decoration: line-through;
      }

      span {
        font-weight: 800;
        font-size: 72px;
      }

      span:last-child {
        font-size: 24px;
        font-weight: bold;
        margin-left: 12px;
      }
    }
    .description {
      margin-top: 20px;
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .button {
      cursor: pointer;
      padding: 14px 125px 14px 125px;
      background-color: #274568;
      border-radius: 6px;
      margin-top: 20px;
      transition: background .3s linear;

      &:hover {
        background: $landing-dark-grey;
      }

      span {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.25px;
        color: $white;
      }
    }
  }
}

.features {
  margin-top: 40px;
  .feature_title {
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
  }
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .features_list {
      margin-top: -32px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      flex-basis: 30%;
      .feature {
        margin-top: 32px;
        padding: 5px 0 5px 0;
        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
          letter-spacing: 0.15px;
          margin-left: 20px;
          text-transform: uppercase;
        }
        :global(.MuiSvgIcon-root) {
          position: relative;
          top: 6px;
          font-size: 26px;
          fill: rgba(39, 69, 104, 0.7);
        }
      }
    }
  }

}

.modal_container {
  width: 50%;
  max-width: $max-page-width;
  margin: auto;
}


.nav_modal {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  padding: 100px 0;
  transition: top 0.3s ease;
  font-family: $montserrat;

  &:global(.visible) {
    top: 0;
  }

  .navigation {
    display: flex;

    nav {
      display: flex;
      width: 100%;
    }

    a {
      position: relative;
      font-family: 'Noto Sans', 'Montserrat', sans-serif;
      transition: 0.1s color linear;

      .feature_title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;
      }

      .feature_description {
        font-family: $montserrat;
        font-size: 14px;
        font-weight: 400;
        color: $landing-dark-grey;
        letter-spacing: 0.5px;
      }

      :global(span) {
        margin-right: 12px;
      }

      :global(svg) {
        font-size: 20px;
        line-height: 20px;
        opacity: 0;
        transition: 0.25s opacity linear;
        color: $primary;
      }

      &:hover {
        color: $primary;

        .feature_title {
          color: $primary;
        }

        :global(svg) {
          opacity: 1;
        }

        .feature_description {
          color: $primary;
        }
      }
    }

    .nav_block {
      width: 25%;

      > a {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;

        &:hover {
          color: $primary;
        }
      }
    }

    .feature_block {
      width: 25%;
      padding-right: 40px;
      margin-bottom: 20px;
    }
  }

  .nav_header_row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .nav_title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    color: $landing-dark-grey;
    margin-right: auto;
  }

  .close_button {
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    svg {
      font-size: 40px;
    }
  }
}

.modal_overlay {
  background-color: transparent;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease;

  &:global(.visible) {
    bottom: 0;
    background-color: $landing-overlay;
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

.promo {
  display: flex;
  justify-content: space-between;
  //align-items: baseline;
}

.promo_text {
  align-items: center;

  div:first-child {
    font-size: 36px;
    font-weight: 700;
  }

}

.try_free {
  padding: 40px;
  background: #2745681A;
  margin-top: 85px;

  .free_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      /* or 38px */


      /* Dark grey */

      color: #16293F;
    }

    .free_button {
      min-width: 270px;
      padding: 14px 22px;
      background: #274568;
      border-radius: 6px;
      transition: background .3s linear;
      cursor: pointer;
      text-align: center;

      &:hover {
        background: $landing-dark-grey;
      }

      span {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */

        letter-spacing: 0.25px;
        color: #F6F8FA;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 1400px) {
  .root {
    padding: 60px;
  }

  .tariff {
    padding: 0 20px 20px 20px !important;
    flex-basis: 30%;

    .price {
      text-align: center;

      span:first-child {
        font-size: 24px !important;
      }

      span {
        font-size: 48px !important;
      }

      span:last-child {
        font-size: 16px !important;
      }
    }

    .description {
      text-align: center;

      span {
        font-size: 16px !important;
      }
    }

    .badge {
      padding: 8px 0px 8px 40px !important;
    }

    .button {
      padding: 14px 0 14px 0 !important;
      align-items: center;
      text-align: center;
    }
  }
  .badge {
    margin-left: -20px !important;
  }
  .container {
    padding-right: 20px;
  }

  .feature {
    span {
      font-size: 14px !important;
    }
  }
  .subtitle {
    font-size: 20px;
  }

  .tariffs_container {
    margin-top: 40px;
  }

  .try_free {
    .free_wrapper {
      .free_button {
        min-width: auto;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px 20px;
  }

  .container {
    padding-left: 20px;
  }
  .tariff {
    .price {
      display: flex;
      flex-direction: column;
    }
  }
  .tariff:first-child {
    padding-bottom: 0 !important;
    //padding-top: 16px !important;

    // .badge {
    //   span {
    //     //font-size: 14px !important;
    //   }
    // }
  }
  .feature {
    span {
      font-size: 12px !important;
      margin-left: 5px !important;
    }
  }
  .subtitle {
    font-size: 18px;
  }
}

@media (max-width: $mobile-width) {
  .root {

    :global(.section-subtitle) {
      display: none;
    }
  }

  .promo {
    flex-direction: column;
  }

  .promo_text {
    div:first-child {
      font-size: 18px;
    }

    div:last-child {
      font-size: 16px;
    }
  }

  .container {
    padding: 0;
  }
  .features {
    flex-wrap: nowrap !important;
    flex-direction: column;

    .features_list {
      margin-top: 0px !important;

      .feature {
        margin-top: 0 !important;
        margin-bottom: 32px !important;
        padding: 0 !important;

        span {
          font-size: 16px !important;
          margin-left: 10px !important;
        }
      }
    }
  }
  .tariffs {
    flex-direction: column;

    .tariff {
      margin-bottom: 20px;
      padding: 20px !important;

      & {
        .price {
          margin-top: 5px !important;
        }
      }

      .badge {
        margin-top: 0 !important;

      }

      .description {
        margin-top: 0 !important;
        text-align: left;
      }

      .price {
        //margin-top: 40px !important;
        text-align: center;

        span:first-child {
          font-size: 18px !important;
        }
      }
    }
  }

  .try_free {
    margin-top: 45px;

    .free_wrapper {
      flex-direction: column;

      .text {
        font-size: 24px;
        line-height: 140%;
        /* or 34px */

        letter-spacing: 0.18px;

        /* Dark grey */

        color: #16293F;
      }

      .free_button {
        margin-top: 20px;

        &:hover {
          background: $landing-dark-grey;
        }

      
      }
    }
  }

}
