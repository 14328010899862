@import "styles/variables";

.component {
  margin-bottom: $margin-lg;

  :global(.special-label),
  :global(.flag-dropdown) {
    display: none;
  }

  :global(.react-tel-input) {
    +svg {
      position: absolute;
      top: 20px;
      left: 20px;
      color: $landing-grey-50;
      z-index: 2;
    }
  }
}