@import "/styles/variables";

.root {
  padding: 120px 0 35px;
  overflow: hidden;

  :global(.section-title) {
    font-size: 56px;
  }
}

.content {
  display: flex;
  margin-top: 80px;

  .oportunities {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    flex-basis: 60%;

    .opportunity {
      padding: 4px 0 4px 0;
      margin-bottom: 40px;
      cursor: pointer;

      &:visited {
        span {
          color: $primary;
        }

        :global(.MuiSvgIcon-root) {
          fill: $primary;
        }
      }

      &:hover {
        span {
          color: $primary;
        }

        :global(.MuiSvgIcon-root) {
          fill: $primary;
        }
      }

      span {
        font-size: 14px;
        font-weight: 700;
        margin-left: 12px;
        padding-bottom: 5px;
        text-transform: uppercase;
        color: rgba(39, 69, 104, 0.7);
      }

      :global(.MuiSvgIcon-root) {
        top: 7px;
        position: relative;
        fill: rgba(39, 69, 104, 0.7);
      }
    }
  }

  .video {
    margin-left: 130px;
    //width: 777px;
    //height: 518px;
    video {
      width: 100%;
    }

    //background-color: #001023;
  }
}

.more_link {
  font-size: 14px;
  color: $landing-dark-grey;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 5px;
}

.all_features_link {
  margin-bottom: 40px;
  font-size: 14px;
  color: $landing-grey;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $primary
  }

  &:active {
    color: $primary
  }

  svg {
    font-size: 20px;
    position: relative;
    top: -1px
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

@media (max-width: 1400px) {
  .root {
    // padding: 60px;
    padding: 35px 0;
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px;

    video {
      max-width: 100%;
    }
  }
  .content {
    flex-direction: column;

  }
  .oportunities {
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: space-around;
  }
  .video {
    margin-left: 0 !important;
    //height: auto;
    //width: auto;
    max-width: 100%;
  }

}

@media (max-width: $mobile-width) {
  .root {
    padding: 40px 20px;

    :global(.section-title) {
      font-size: 24px;
    }

    :global(.swiper-wrapper) {
      margin-top: 40px;
    }

    :global(.swiper-slide) {
      span {
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        text-transform: uppercase;
      }

      :global(.video-mobile) {
        margin-top: 12px;
        //width: 80vw;
        //background: $landing-dark-grey;
        //height: 247px;
        margin-bottom: 30px;

        video {
          max-width: 100%;
        }
       
      }
    }

    :global(.swiper-pagination) {
      bottom: -10px !important;

      :global(.swiper-pagination-bullet) {
        background: #274568B2;
        border: 4px solid #F6F8FA;
        //box-sizing: border-box;
        width: 20px;
        height: 20px;
      }

      :global(.swiper-pagination-bullet-active) {
        background: $primary;
        border: none;
      }
    }
  }
  .content{
    margin-top:20px;
  }
  .oportunities {
    flex-direction: column;
  }
}