@import "/styles/variables";

$cursor-size: 24px;

.root {
  position: relative;

  :global(.section-title) {
    margin-bottom: 12px;
  }
}

.container {
  display: flex;
  position: relative;
  max-width: $max-page-width;
  margin: auto;
  z-index: 2;
  padding: 120px 0 80px 0px;
  overflow: visible;
}

.text_wrapper {
  min-width: 465px;
  z-index: 2;
}

.image_wrapper {
  margin-left: -65px;
  margin-right: -50px;
  z-index: 1;
}

.description {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 60px;
  color: $landing-grey;
  max-width: 340px;

}

.action_container {
  display: flex;
  flex-direction: column;
  width: 427px;
}

.action {
  margin-bottom: 42px;
  width: 100%;

  :global(.z-button) {
    width: 100%;
  }
}

.payment_description {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: $landing-grey;
  div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    span {
      padding-top: 4px;
    }
  }

  :global(svg) {
    font-size: 20px;
    margin-right: 12px;
  }
}

@media (max-width: 1400px) {
  .root {
    padding: 0 60px 0 60px;
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px !important;
  }
  .container {
    flex-wrap: wrap;
  }

  .text_wrapper {
    order: 2
  }

  .image_wrapper {
    order: 1;
    margin-left: -30px;
    margin-right: -20px;
    margin-bottom: 20px;
  }
}

@media (max-width: $mobile-width) {
  .root {
    padding: 40px 20px !important;
  }

  .container {
    flex-wrap: wrap;
    padding: 0;
  }

  .text_wrapper {
    order: 2
  }

  .image_wrapper {
    order: 1;
    margin-left: -30px;
    margin-right: -20px;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    font-weight: 600;
    max-width: 270px;
  }

  .text_wrapper {
    min-width: auto;
    z-index: 2;
  }

  .action_container {
    width: calc(100vw - 2 * 20px);
  }
}

