@import "/styles/variables";

$cursor-size: 24px;

.root {
  padding: 120px 0;
  //background-color: $landing-back-grey;
  overflow: hidden;
}

.container {
  position: relative;
  max-width: $max-page-width;
  margin: auto;
}

.hide_container {
  position: relative;
  max-width: $max-page-width;
  background-color: $white;
  margin: auto;
}

.swiper_container {
  position: relative;
  max-width: $max-page-width;
  margin: auto;
  //padding-right: 117px;

}

.hide_left {
  position: absolute;
  right: 100%;
  top: 0;
  width: 9999px;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 2;
}

.header_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px
}

.controls {
  display: flex;
  width: 100px;
  justify-content: space-between;
}

.swiper_control {
  cursor: pointer;

  >div {
    &:global(.swiper-button-disabled) {
      opacity: 0.3;
    }
  }

  svg {
    font-size: 36px;
    color: $landing-grey;
  }
}

.review_container {
  height: 100%;
  //max-width: 550px;
}

.link_row {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.video {
  width: 100%;
  height: 380px;
}

.modal_container {
  width: 50%;
  max-width: $max-page-width;
  margin: auto;
}

.nav_modal {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  padding: 100px 0;
  transition: top 0.3s ease;
  font-family: $montserrat;

  &:global(.visible) {
    top: 0;
  }

  .navigation {
    display: flex;

    nav {
      display: flex;
      width: 100%;
    }

    a {
      position: relative;
      font-family: 'Noto Sans', 'Montserrat', sans-serif;
      transition: 0.1s color linear;

      .feature_title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;
      }

      .feature_description {
        font-family: $montserrat;
        font-size: 14px;
        font-weight: 400;
        color: $landing-dark-grey;
        letter-spacing: 0.5px;
      }

      :global(span) {
        margin-right: 12px;
      }

      :global(svg) {
        font-size: 20px;
        line-height: 20px;
        opacity: 0;
        transition: 0.25s opacity linear;
        color: $primary;
      }

      &:hover {
        color: $primary;

        .feature_title {
          color: $primary;
        }

        :global(svg) {
          opacity: 1;
        }

        .feature_description {
          color: $primary;
        }
      }
    }

    .nav_block {
      width: 25%;

      > a {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;

        &:hover {
          color: $primary;
        }
      }
    }

    .feature_block {
      width: 25%;
      padding-right: 40px;
      margin-bottom: 20px;
    }
  }

  .nav_header_row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .nav_title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    color: $landing-dark-grey;
    margin-right: auto;
  }

  .close_button {
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    svg {
      font-size: 40px;
    }
  }
}

.modal_overlay {
  background-color: transparent;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease;

  &:global(.visible) {
    bottom: 0;
    background-color: $landing-overlay;
  }
}

@media (max-width: $content-width) {
  .root {
    padding: 60px;
  }
  .container {
    padding-right: 20px;
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 20px;
  }

  .container {
    padding: 0 20px;
  }

  .swiper_container {
    padding-left: 20px;
    padding-right: 117px;
  }

  .header_row {
    margin-bottom: 60px;
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px 20px;

    :global(.swiper-pagination) {
      bottom: -20px !important;

      :global(.swiper-pagination-bullet) {
        background: #274568B2;
        border: 4px solid #F6F8FA;
        //box-sizing: border-box;
        width: 20px;
        height: 20px;
      }

      :global(.swiper-pagination-bullet-active) {
        background: $primary;
        border: none;
      }
    }
  }

  .review_container {
    margin: 20px;
  }

  .swiper_container {
    padding-left: 20px;
    padding-right: 60px;
  }

  .header_row {
    margin-bottom: 40px;
  }

  .modal_container {
    width: 100%;
  }
}

@media (max-width: $mobile-width) {
  .root {
    padding: 40px 0;
  }
}

