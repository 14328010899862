@import "/styles/variables";

.root {
  padding: 120px 0;
  overflow: hidden;
  background: $landing-back-grey;

  :global(.section-title) {
    font-weight: 800;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #16293F;
  }

  :global(.section-subtitle) {
    margin-top: 12px;
    white-space: pre-line;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.15px;
    color: #274568;
  }
}

.modes {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .mode:not(:first-child) {
    margin-top: 12px;
  }

  .mode {
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.15px;
    color: #274568;
  }
}

.content {
  display: flex;
  margin-top: 80px;
  justify-content: space-between;

  .plate {
    text-decoration: none;
    color: #274568;
    background: rgba(39, 69, 104, 0.1);
    cursor: pointer;

    &:hover {
      background: rgba(39, 69, 104, 0.2);
    }

    border-radius: 8px;
    padding: 20px 0 20px 0;
    flex-grow: 1;

    &:not(:first-child) {
      margin-left: 40px;
    }

    span {
      margin-left: 80px;
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      color: #16293F;
    }

    :global(.MuiSvgIcon-root) {
      position: absolute;
      font-size: 30px;
      margin-left: 25px;
      margin-top: 6px;
      //padding: 25px 0 25px 25px;
    }
  }
}

.action {
  margin-top: 40px;
  cursor: pointer;
  padding: 22px 149px 22px 149px;
  background: rgba(255, 0, 52, 0.7);
  border-radius: 6px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  max-width: 380px;
  color: $white;
  font-weight: bold;
  line-height: 140%;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  transition: background .3s linear;

  &:hover {
    background: $primary;
  }
}

.ui_rows {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  :global(.field) {
    flex-grow: 1;

    input {
      padding: 21px 20px 21px 20px !important;
    }

    &:first-child {
      margin-right: 40px;
    }
  }
}

.modal_container {
  width: 50%;
  max-width: $max-page-width;
  margin: auto;
}

.nav_modal {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  padding: 100px 0;
  transition: top 0.3s ease;
  font-family: $montserrat;

  &:global(.visible) {
    top: 0;
  }

  .navigation {
    display: flex;

    nav {
      display: flex;
      width: 100%;
    }

    a {
      position: relative;
      font-family: 'Noto Sans', 'Montserrat', sans-serif;
      transition: 0.1s color linear;

      .feature_title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;
      }

      .feature_description {
        font-family: $montserrat;
        font-size: 14px;
        font-weight: 400;
        color: $landing-dark-grey;
        letter-spacing: 0.5px;
      }

      :global(span) {
        margin-right: 12px;
      }

      :global(svg) {
        font-size: 20px;
        line-height: 20px;
        opacity: 0;
        transition: 0.25s opacity linear;
        color: $primary;
      }

      &:hover {
        color: $primary;

        .feature_title {
          color: $primary;
        }

        :global(svg) {
          opacity: 1;
        }

        .feature_description {
          color: $primary;
        }
      }
    }

    .nav_block {
      width: 25%;

      > a {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;

        &:hover {
          color: $primary;
        }
      }
    }

    .feature_block {
      width: 25%;
      padding-right: 40px;
      margin-bottom: 20px;
    }
  }

  .nav_header_row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .nav_title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    color: $landing-dark-grey;
    margin-right: auto;
  }

  .close_button {
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    svg {
      font-size: 40px;
    }
  }
}

.modal_overlay {
  background-color: transparent;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease;

  &:global(.visible) {
    bottom: 0;
    background-color: $landing-overlay;
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

@media(max-width: 1400px) {
  .root {
    padding: 60px;
  }
  .plate {
    &:not(:first-child) {
      margin-left: 20px !important;
    }

    span {
      font-size: 24px !important;
    }
  }
}

@media (max-width: $tablet-width) {
  .modal_container {
    width: 100%;
    padding: 20px;
  }
  .root {
    padding: 40px;
  }
  .plate {
    span {
      font-size: 16px !important;
    }
  }
  .plate {
    :global(.MuiSvgIcon-root) {
      margin-top: 0 !important;
    }
  }
}

@media (max-width: $mobile-width) {
  .modal_container {
    width: 100%;
    padding: 20px;

    .ui_rows {
      flex-wrap: wrap !important;

      :global(.field) {
        &:first-child {
        }

        margin-right: 0 !important;
      }
    }
  }
  .root {
    padding: 40px 20px;

    :global(.section-title) {
      font-size: 32px;
    }

    :global(.section-subtitle) {
      font-size: 16px;
    }

    .content {
      flex-direction: column;

      .plate {
        margin-left: 0 !important;
        margin-bottom: 20px;
        span {
          font-size: 24px !important;
        }
      }
    }
  }
}