@import 'styles/variables';

.card{
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;

  &__side{
    position: absolute;
    top: 0;
    left: 0;
    transition: all .8s ease;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: $white;

    &--front{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--back{
      transform: rotateY(180deg);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding: $padding-lg;

      @media (max-width: $mobile-width) {
        padding: rem-calc(8);
      }
    }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  &__img-wrapper{
    padding: 0 10px;
  }

  &__img{
    display: block;
    max-width: 100%;
  }

  &__heading-back-side{
    color: rgba(39, 69, 104, 0.7);
    font-size: $font-md1;
    font-weight: 700;

    @media (max-width: $mobile-width) {
      font-size: rem-calc(12);
    }
  }

  &__description-back-side{
    font-size: $font-sm2;
    font-weight: 500;
    margin-top: $margin-sm;

    @media (max-width: $mobile-width) {
      font-size: rem-calc(11);
    }
  }

  &__link{
    color: $landing-dark-grey;
    font-size: $font-sm;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: $mobile-width) {
      font-size: $font-xs;
    }

    &:hover{
      color: $landing-dark-grey;
    }
  }
}