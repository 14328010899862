@import 'styles/variables';

.partners {
  background-color: $landing-back-grey;
  padding: 120px 0;

  @media (max-width: $content-width) {
    padding: 60px;
  }

  @media (max-width: $tablet-width) {
    padding: 40px;
  }

  @media (max-width: $mobile-width) {
    padding: 40px 20px;
  }

  &__wrapper{
    max-width: $max-page-width;
    margin: auto;
  }

  &__heading{
    @media (max-width: rem-calc(650)) {
      font-size: rem-calc(32);
    }
  }

  &__cards-wrapper{
    margin-top: rem-calc(40);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(273px, 1fr);
    gap: rem-calc(40);
    margin-bottom: rem-calc(40);

    @media (max-width: rem-calc(750)) {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(250px, 1fr);
      gap: rem-calc(20);
    }
  }
}