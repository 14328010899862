@import 'styles/variables';

.attraction {
  background: rgba(39, 69, 104, 0.1);
  padding: rem-calc(40);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: rem-calc(20);

  &__heading{
    font-size: rem-calc(32);
    font-weight: 700;

    @media (max-width: $mobile-width) {
      font-size: rem-calc(24);
    }
  }
}