@import "/styles/variables";

.root {
  padding: 120px 0 120px;
  background-color: #F6F8FA;
  overflow: hidden;
  :global(.section-title) {
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.5px;
    white-space: pre-line;
    font-size: 56px;
  }
}

.benefits {
  display: flex;
  flex-wrap: wrap;
}

.benefit {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-basis: 31%;
  margin-top: 80px;
  margin-right: 20px;

  .benefit_icon {
    color: $primary;

    :global(.MuiSvgIcon-root) {
      font-size: 66px;
    }
  }

  .benefit_text {
    font-size: 24px;
    font-weight: 700;
  }
}

.demo {
  margin-top:80px;
  padding: 40px;
  background-color: rgba(255, 0, 52, 0.1);
  display: flex;
  .demo_button {
    background-color: $primary-light;
    padding: 22px 122.5px 22px 122.5px;
    border-radius: 6px;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 40px;
    cursor: pointer;
    transition: background-color .2s ease-in;

    &:hover {
      background-color: $primary;
    }
  }
  .demo_text {
    padding: 10px 0 10px 0;
    span {
      line-height: 140%;
      letter-spacing: 0.5px;
      font-size: 16px;
      font-weight: 500;
      display: block;
    }
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

@media (max-width: 1400px) {
  .root {
    padding: 60px;
  }
  .benefits {
    justify-content: space-evenly !important;
  }
  .demo {
    padding: 20px !important;

    .demo_button {
      margin-right: 20px !important;
    }
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px;
  }
  .demo {
    .demo_text {
      span {
        font-size: 12px !important;
      }

    }

    .demo_button {
      padding: 20px 40px 20px 40px;
    }
  }
}

@media (max-width: $mobile-width) {
  .root {
    padding: 40px 20px;

    :global(.swiper-pagination) {
      bottom: -60px !important;

      :global(.swiper-pagination-bullet) {
        background: #274568B2;
        border: 4px solid #F6F8FA;
        //box-sizing: border-box;
        width: 20px;
        height: 20px;
      }

      :global(.swiper-pagination-bullet-active) {
        background: $primary;
        border: none;
      }
    }
    :global(.section-title) {
      font-size: 32px;
    }
  }
  .benefits {
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 400%;
    width: 300%;
    justify-content: flex-start !important;
    position: relative;


    .benefit {
      flex-basis: 25%;
      //margin-right: 80px;
      margin-left: 80px;
      max-width: 70vw;

      .benefit_text {
        font-size: 20px;
      }
    }
  }
  .demo {
    padding: 12px !important;
    flex-direction: column;

    .demo_button {
      text-align: center;
      font-size: 14px;
      margin-right: 0 !important;
    }

    .demo_text {
      span {
        font-size: 14px !important;
      }
    }
  }
}