@import "/styles/variables";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $landing-grey;
  background: $landing-back-grey;
  //max-width: 550px;
  padding: 20px 20px 30px;

  .author {
    display: flex;
    margin-bottom: 20px;
  }

  .avatar {
    margin-right: 20px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid $landing-grey-10;
    }
  }

  .name {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1.4;
    font-weight: bold;
    letter-spacing: 0.18px;
    margin-top: 13px;
  }

  .benefits {
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: rgba(39, 69, 104, 0.7);
    }

    ul {
      margin-left: -15px;
      margin-top: 8px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  .limit {
    margin-top: 20px;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: rgba(39, 69, 104, 0.7);
    }

    div {
      margin-top: 8px;
      font-size: 16px;
      line-height: 140%;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: #16293F;
    }
  }

  .review {
    margin-top: 20px;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: rgba(39, 69, 104, 0.7);
    }

    .text {
      margin-top: 8px;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.5px;
      color: #16293F;
      display: flex;

      .video {
        padding: 21px 40px 23px 40px;
        background: $landing-dark-grey;
        color: $white;
        margin-right: 12px;
        max-height: 66px;
        max-width: 99px;
        cursor: pointer;
        border-radius: 4px;
      }
    }

    .review_text {
      font-weight: 500;
    }
  }

  .read {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 140%;
      text-align: right;
      letter-spacing: 0.25px;
      text-transform: uppercase;
      color: #16293F;
      cursor: pointer;
    }
  }

  .position {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-weight: 500;
  }

  .text {
    font-size: 16px;
    line-height: 1.4;
    margin-top: auto;
    flex-grow: 1;
    letter-spacing: 0.5px;
    white-space: pre-line;
  }
}

@media (max-width: $mobile-width) {
  .author {
    flex-direction: column;
  }
}