@import "/styles/variables";

.root {
  padding: 35px 0 100px;
  overflow: hidden;
  :global(.section-title) {
    font-weight: 800;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -0.5px;
    white-space: pre-line;
  }
}

.content {
  margin-top: 80px;
}

.process {
  background: $landing-back-grey;
  clip-path: polygon(50% 15%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);

  &:not(:first-child) {
    margin-top: -40px;
  }

  &:last-child {
    clip-path: polygon(50% 15%, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);

    .process_content {
      flex-direction: row;
      padding: 100px 0 0 0;
      margin-left: 117px;
    }
  }

  &:nth-child(2) {
    .process_content {
      flex-direction: row-reverse;

      .process_description {
        margin-left: 117px;
        padding-top: 50px;
      }
    }
  }

  &:nth-child(3) {
    .process_content {
      .process_description {
        margin-right: 115px;
        padding-top: 50px;
      }
    }
  }

  &:first-child {
    .process_content {
      justify-content: space-evenly;

      .process_image {
        margin-left: -10%;
      }
    }
  }
}

.process_content {
  padding: 100px 0 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.process_description {
  padding-top: 50px;

  span {
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 32px;
  }

  & ul {
    margin-left: -10px;

    li {
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.15px;
      white-space: pre-line;
    }
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

@media (max-width: 1400px) {
  .root {
    // padding: 60px;
    padding: 35px 0;
  }
  .process {
    .process_content {
      flex-wrap: nowrap;
    }

    &:last-child {
      .process_content {
        padding: 50px 0 0 0;
      }
    }

    &:nth-child(even) {
      .process_content {
        .process_description {
          margin-left: 60px;
          padding-top: 10px;
        }
      }
    }

    &:nth-child(3) {
      .process_content {
        .process_description {
          margin-right: 60px;
          padding-top: 10px;
        }
      }
    }

    &:first-child {
      .process_content {
        justify-content: space-evenly;

        .process_image {
          margin-left: -10%;
        }
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px;
  }
  .process_description {
    li {
      font-size: 14px !important;
    }
  }
}

@media (max-width: $mobile-width) {
  .root {
    padding: 20px 0 80px 0;

    :global(.section-title) {
      padding: 0 10px 0 10px;
      font-size: 36px;
    }
  }
  .process {
    .process_content {
      flex-direction: column !important;
      max-width: calc(100vw - 5px);
    }

    &:first-child {
      .process_content {
        //justify-content: space-evenly;
        flex-direction: column !important;

        .process_image {
          margin-left: 0;

          img {
            width: 100%;
          }
        }

        .process_description {
          padding-top: 0;
          margin-top: 40px;
          padding-left: 10px;
          margin-bottom: 80px;
        }
      }
    }

    &:nth-child(even) {
      .process_content {
        .process_description {
          margin-left: 0;
          padding-left: 10px;
          padding-top: 10px;
        }

        .process_image {
          margin-bottom: 30px;

          img {
            width: 130%;
            max-width: 200%;
            position: relative;
            left: -20%;
          }
        }
      }
    }

    &:nth-child(3) {
      .process_content {
        .process_description {
          padding-top: 10px;
          margin: 20px 0 50px 10px;
        }

        .process_image {
          margin-top: 10px;

          img {
            width: 130%;
            max-width: 200%;
            position: relative;
            left: -20%;
          }
        }
      }
    }

    &:last-child {
      .process_content {
        padding: 50px 0 0 0;
        flex-direction: column-reverse !important;
        margin-left: 0 !important;

        .process_image {
          margin-top: 40px;
          padding-bottom: 20px;
        }

        .process_description {
          margin-top: -40px;
        }
      }
    }

  }
}