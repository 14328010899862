@import 'styles/variables';

.demo {
  margin-top: 80px;
  padding: 40px;
  background-color: rgba(255, 0, 52, 0.1);
  display: flex;

  @media (max-width: 1400px) {
    padding: 20px !important;
  }

  @media (max-width: $mobile-width) {
    padding: 12px !important;
    flex-direction: column;
  }

  &__button {
    background-color: $primary-light;
    padding: 22px 122.5px 22px 122.5px;
    border-radius: 6px;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 40px;
    cursor: pointer;
    transition: background-color .2s ease-in;

    &:hover {
      background-color: $primary;
    }

    @media (max-width: 1400px) {
      margin-right: 20px !important;
    }

    @media (max-width: $tablet-width) {
      padding: 20px 40px 20px 40px;
    }

    @media (max-width: $mobile-width) {
      text-align: center;
      font-size: 14px;
      margin-right: 0 !important;
    }
  }

  &__text {
    padding: 10px 0 10px 0;

    span {
      line-height: 140%;
      letter-spacing: 0.5px;
      font-size: 16px;
      font-weight: 500;
      display: block;

      @media (max-width: $tablet-width) {
        font-size: 12px;
      }

      @media (max-width: $mobile-width) {
        font-size: 14px !important;
      }
    }
  }
}