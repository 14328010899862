@import 'styles/variables';

.statistics {
  padding: rem-calc(120) 0;
  max-width: 85rem;
  margin: auto;

  @media (max-width: rem-calc(1400)) {
    padding: rem-calc(60);
  }

  @media (max-width: $tablet-width) {
    padding: rem-calc(40);
  }

  @media (max-width: 650px) {
    padding: rem-calc(40) rem-calc(20);
  }

  &__title{
    margin-bottom: rem-calc(12) !important;

    @media (max-width: rem-calc(650)) {
      font-size: rem-calc(32);
    }
  }

  &__subtitle{
    margin-bottom: rem-calc(80);
    font-size: rem-calc(20);
    font-weight: 700;
    line-height: 1.4;
    display: flex;
    flex-direction: column;

    @media (max-width: rem-calc(650)) {
      font-size: rem-calc(16);
    }
  }

  &__img-wrapper{
    @media (max-width: rem-calc(800)) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__img{
    display: block;
    max-width: 100%;

    @media (max-width: rem-calc(800)) {
      display: none !important;
    }
  }

  &__img-mobile{
    display: none !important;

    &:last-child{
      margin-top: rem-calc(40);
    }

    @media (max-width: rem-calc(800)) {
      display: block !important;
    }
  }
}