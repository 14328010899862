@import 'styles/variables';

.item {
  &__img {
    transform: skewY(4deg);

    @media (max-width: rem-calc(1200)) {
      transform: skewY(0);
    }
  }

  &__card {
    position: absolute;
    z-index: 10;
    top: 180px;
    transform: skewY(4deg);

    @media (max-width: rem-calc(1200)) {
      position: static;
      transform: skewY(0);
    }
  }

  &__year{
    display: inline-block;
    font-size: rem-calc(40);
    font-weight: 800;
    margin-bottom: 30px;
  }

  &__content {
    font-size: $font-sm;
    color: rgba(39, 69, 104, 0.7);
    letter-spacing: .25px;
    max-width: rem-calc(140);

    &:not(:last-child) {
      margin-bottom: rem-calc(12);
    }
  }

  &__heading {
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  &__label {

  }
}