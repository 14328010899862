@import 'styles/variables';

.history{
  --s: 100px;
  background:
    linear-gradient(to top left ,$landing-back-grey 100%,#0000 100%) top,
    linear-gradient($landing-back-grey 0 0) center,
    linear-gradient(to bottom right ,$landing-back-grey 50%,#0000 51%) bottom;
  background-size: 100% var(--s),100% calc(100% - 2*var(--s));
  background-repeat: no-repeat;

  @media (max-width: rem-calc(1400)) {
    padding: 0 rem-calc(60);
  }

  @media (max-width: rem-calc(1200)) {
    padding: rem-calc(60);
  }

  @media (max-width: rem-calc(1200)) {
    background-color: $landing-back-grey;
    background-size: cover;
  }

  @media (max-width: $tablet-width) {
    padding: 40px;
  }

  @media (max-width: $mobile-width) {
    padding: 40px 20px;
  }

  &__container{
    max-width: $max-page-width;
    margin: auto;
    padding: 120px 0;
    margin-bottom: 450px;

    @media (max-width: rem-calc(1200)) {
     margin-bottom: 0;
      padding: 0;
    }
  }

  &__heading{
    @media (max-width: rem-calc(650)) {
      font-size: rem-calc(32);
    }
  }

  &__scroll {
    scrollbar-color: #FF0034 #FF007A1A;
    scrollbar-width: 4px;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track{
      background-color: #FF007A1A;
    }

    &::-webkit-scrollbar-thumb{
      background-color: #FF0034;
    }

    &::-webkit-scrollbar-thumb:hover{
      background-color: #FF0034;
    }
  }

  &__wrapper{
    display: flex;
    margin-top: rem-calc(80);
    padding-bottom: rem-calc(40);
  }

  &__content{
    //display: flex;

    display: grid;
    grid-template-columns: repeat(7, minmax(140px, 1fr));
    position: relative;
    transform: skewY(-4deg);
    margin-top: rem-calc(80);
    margin-bottom: -50px;

    @media (max-width: rem-calc(1200)) {
      transform: skewY(0);
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      padding-bottom: $padding-sm;
      gap: rem-calc(40);
      margin-bottom: 0;
    }
  }
}