@import "/styles/variables";

.root {
  padding: 120px 0 120px;
  overflow: hidden;

  :global(.section-title) {
    font-size: 56px;
  }
}

.forwhom {
  //margin-top: 80px;
  .forwhom_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.case {
  margin-top: 80px;
  //margin-right: 40px;
  min-width: 415px;
  flex-basis: 33.3%;
  .case_icon {
    color: $primary;
    :global(.MuiSvgIcon-root) {
      font-size: 66px;
    }
  }
  .case_name {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 700;
    color: $landing-dark-grey;
  }
  .case_roles {
    margin-top: 20px;
    .case_role {
      font-weight: 600;
      display: inline-block;
      padding: 11px 16px;
      color: $primary-dark;
      text-transform: uppercase;
      border: 1px solid rgba(255, 0, 52, 0.3);
      border-radius: 8px;
      margin-right: 12px;
    }
  }
  .case_solutions {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    ul {
      padding-inline-start: 20px;
    }
  }
}

.demo {
  margin-top:80px;
  padding: 40px;
  background-color: rgba(255, 0, 52, 0.1);
  display: flex;
  .demo_button {
    background-color: $primary-light;
    padding: 22px 75px 22px 75px;
    border-radius: 6px;
    color: $white;
    margin-right: 40px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    transition: background-color .2s ease-in;
    text-transform: uppercase;

    &:hover {
      background-color: $primary;
    }
  }
  .demo_text {
    padding: 10px 0 10px 0;
    span {
      font-size: 16px;
      font-weight: 500;
      display: block;
    }
  }
}

.container {
  max-width: $max-page-width;
  margin: auto;
}

@media (max-width: 1400px) {
  .root {
    padding: 60px;
  }
  .forwhom_wrapper {
    justify-content: space-around !important;
  }
  .demo {
    padding: 20px !important;

    .demo_button {
      margin-right: 20px !important;
    }
  }
}

@media (max-width: $tablet-width) {
  .root {
    padding: 40px;
  }
  .forwhom_wrapper {
    flex-wrap: wrap !important;
  }
  .demo {
    .demo_text {
      span {
        font-size: 12px !important;
      }

    }

    .demo_button {
      padding: 20px 40px 20px 40px;
    }
  }
}

@media (max-width: $mobile-width) {
  .root {
    padding: 40px 20px;

    .case {
      margin-top: 40px;
    }

    :global(.swiper-pagination) {
      bottom: -60px !important;

      :global(.swiper-pagination-bullet) {
        background: #274568B2;
        border: 4px solid #F6F8FA;
        //box-sizing: border-box;
        width: 20px;
        height: 20px;
      }

      :global(.swiper-pagination-bullet-active) {
        background: $primary;
        border: none;
      }
    }

    :global(.section-title) {
      font-size: 32px;
    }
  }
  .case_roles {
    max-width: 80vw;
  }
  .case_name {
    span {
      font-size: 20px;
    }
  }
  .case_solutions {
    max-width: calc(100vw - 2 * 20px);
  }
  .case_role {
    &:first-child {
      margin-bottom: 20px;
    }

    padding: 8px 12px 8px 12px !important;
  }
  .case_solutions {
    white-space: pre-line;
  }
  .demo {
    padding: 12px !important;
    flex-direction: column;

    .demo_button {
      margin-right: 0 !important;
      padding: 20px 20px;
      text-align: center;
    }

    .demo_text {
      span {
        font-size: 14px !important;
      }
    }
  }
  .forwhom_wrapper {
    flex-wrap: wrap !important;
    margin-left: 80px;
  }

}